import React, { Fragment, useState } from "react";
import PropTypes from "prop-types";
import "./pricing3.css";
import Modal from "../../Modal/Modal";
import Card from "../../Card/Card";
import { useLocation } from "react-router-dom";
import LoadingModal from "../../LoadingModal/LoadingModal";

const Pricing3 = (props) => {
  const location = useLocation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedDiagnosticCenter, setSelectedDiagnosticCenter] = useState();
  const [diagnosticCentersList, setDiagnosticCentersList] = useState([]);
  const [isLoadingModalOpen, setIsLoadingModalOpen] = useState(false);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const {selectedTestName,diagnosticCenters}=location.state

  const modalDetails = {
    ...location.state,
    diagnosticCenterId: selectedDiagnosticCenter,
  };

  return (
    <div
      className={`pricing3-pricing4 thq-section-padding ${props.rootClassName}`}
    >
      <LoadingModal isOpen={isLoadingModalOpen} />
      <Modal isOpen={isModalOpen} onClose={closeModal} details={modalDetails} />

      <div className="pricing3-max-width thq-section-max-width">
        <div className="pricing3-section-title">
          <div className="pricing3-content">
            <div>
              <h2 className="pricing3-text24 thq-heading-2">
                Find the Best Health Test Prices Near You
              </h2>
            </div>
            <div>
              <p className="pricing3-text35 thq-body-large">
                <span className="selected-test">
                  Selected test:
                  <span
                    style={{
                      fontSize: "20px",
                      fontWeight: "bold",
                      marginLeft: "5px",
                    }}
                  >
                    {selectedTestName}
                  </span>
                </span>
              </p>
            </div>
          </div>
        </div>

        {diagnosticCenters.map((diagnosticCenter) => (
          <Card
            key={diagnosticCenter._id}
            props={props}
            details={diagnosticCenter}
            handleClick={() => {
              setSelectedDiagnosticCenter(diagnosticCenter._id);
              openModal();
            }}
          />
        ))}
      </div>
    </div>
  );
};


Pricing3.defaultProps = {
  plan1Detail: undefined,
  rootClassName: "",
  plan1Feature5: undefined,
  plan1Feature4: undefined,
  plan1Feature10: undefined,
  plan1: undefined,
  plan1Includes: undefined,
  heading1: undefined,
  content1: undefined,
  plan1Feature6: undefined,
  plan1Action: undefined,
  plan1Feature8: undefined,
  plan1Price: undefined,
  plan1Feature1: undefined,
  plan1Feature2: undefined,
  plan1Feature7: undefined,
  plan1Feature9: undefined,
  plan1Feature3: undefined,
  content2: undefined,
};

Pricing3.propTypes = {
  plan1Detail: PropTypes.element,
  rootClassName: PropTypes.string,
  plan1Feature5: PropTypes.element,
  plan1Feature4: PropTypes.element,
  plan1Feature10: PropTypes.element,
  plan1: PropTypes.element,
  plan1Includes: PropTypes.element,
  heading1: PropTypes.element,
  content1: PropTypes.element,
  plan1Feature6: PropTypes.element,
  plan1Action: PropTypes.element,
  plan1Feature8: PropTypes.element,
  plan1Price: PropTypes.element,
  plan1Feature1: PropTypes.element,
  plan1Feature2: PropTypes.element,
  plan1Feature7: PropTypes.element,
  plan1Feature9: PropTypes.element,
  plan1Feature3: PropTypes.element,
  content2: PropTypes.element,
};

export default Pricing3;
