import { FaSpinner } from "react-icons/fa"; 

const spinnerStyle = {
  fontSize: "1.5rem",
  animation: "spin 1s linear infinite",
};

const LoadingModal = ({
  isOpen,
  onClose,
  details,
  isLoading,
  setIsLoading,
}) => {
  return (
    <>
      {isOpen ? (
        <div className="modal-transparent">
          <div onClick={(e) => e.stopPropagation()} className="modal-white-loading">
            <FaSpinner style={spinnerStyle}/>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default LoadingModal;
